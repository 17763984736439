import type { PropsWithChildren } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

type WindowContextProps = {
  clientHeight: number;
  clientWidth: number;
};
export const WindowContext = React.createContext<WindowContextProps>({ clientHeight: 0, clientWidth: 0 });
export const WindowContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const getVh = useCallback(() => {
    return typeof document === 'undefined'
      ? 1080
      : Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  }, []);
  const getVw = useCallback(() => {
    return typeof document === 'undefined'
      ? 1920
      : Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  }, []);
  const [clientHeight, setVh] = useState<number>(getVh());
  const [clientWidth, setVw] = useState<number>(getVw());
  useEffect(() => {
    const handleResize = () => {
      setVh(getVh());
      setVw(getVw());
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [getVh, getVw]);
  return <WindowContext.Provider value={{ clientHeight, clientWidth }}>{children}</WindowContext.Provider>;
};
