import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ArrowDropDown, ArrowDropUp, KeyboardArrowDown } from '@mui/icons-material';

import { useClientSize } from '~/hooks/use-client-size';

import styles from './styles.module.css';

import Portal from './portal';
import Required from './required';
import { InlineWrapper, Note, Overlay, SpanLabel } from './styled';
import { withStyledComponents } from './with-styled-component';
import { debounce } from 'lodash';
import classNames from 'classnames';

export const DROPDOWN_HEIGHT = 180;

export const LabelButton = withStyledComponents(styles.SelectLabelButton, 'button', {
  showAbove: styles.showAbove,
  isPlaceholder: styles.isPlaceholder,
  small: styles.small,
  large: styles.large,
});
export const ListWrapper = withStyledComponents(styles.SelectListWrapper, 'ul', {
  showAbove: styles.showAbove,
});

export const SearchInput = withStyledComponents(styles.SelectStyledSearchInput, 'input', {
  small: styles.small,
  large: styles.large,
  showAbove: styles.showAbove,
});

export const SelectWrapper = withStyledComponents(styles.SelectWrapper, 'div', {
  small: styles.small,
  fullWidth: styles.fullWidth,
});
export const Icon = withStyledComponents(styles.Icon, 'span');
export const GroupLabel = withStyledComponents(styles.GroupLabel, 'span');
export const EmptyState = withStyledComponents(styles.SelectEmptyState, 'li');

type SelectOption<T extends unknown = string> = { value?: T; label: string; [key: string]: any };
type SelectGroup<T extends unknown = string> = { label: string; options: SelectOption<T>[] };

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}
export function isGroup<T extends unknown = string>(item: unknown): item is SelectGroup<T> {
  return isObject(item) && typeof item['options'] === 'object';
}
export function isOption<T extends unknown = string>(item: unknown): item is SelectOption<T> {
  return isObject(item) && !item.options;
}

const getSelectedOption = <T extends unknown = string>(
  val: T | undefined,
  options: (SelectOption<T> | SelectGroup<T>)[],
): SelectOption<T> | undefined => {
  let selectedOption;

  for (let index = 0; index < options.length; index++) {
    const opt = options[index];

    if (isGroup<T>(opt)) {
      selectedOption = opt.options.find(({ value }) => value === val);
    } else if (isOption<T>(opt) && opt.value === val) {
      selectedOption = opt;
    }

    if (selectedOption) {
      break;
    }
  }

  return selectedOption;
};

export const SelectSearch = <T extends unknown = string>({
  options,
  onChange,
  value,
  placeholder,
  small,
  large,
  overlayedLabel,
  label,
  required,
  note,
  fullWidth,
  disabled,
  search,
  searchPlaceholder,
}: {
  options: (SelectOption<T> | SelectGroup<T>)[];
  onChange: (opt: SelectOption<T> | undefined) => void;
  value?: T;
  label?: string | JSX.Element;
  note?: string;
  disabled?: boolean;
  placeholder?: string;
  searchPlaceholder?: string;
  small?: boolean;
  overlayedLabel?: boolean;
  large?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  search?: boolean;
}) => {
  const { clientHeight } = useClientSize();
  const [searchString, setSearchString] = useState('');
  const [localValue, setLocalValue] = useState(value);
  const [highlightedOption, setHighlightedOption] = useState(-1);
  const [hiddenGroup, setHiddenGroup] = useState<number[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const selectedOption = getSelectedOption(localValue, options);

  const handleSelection = (newVal?: T) => {
    if (disabled) {
      return;
    }
    const newSelectedOption = getSelectedOption(newVal, options);

    debouncedHideHandler(false);
    onChange(newSelectedOption);
    setLocalValue(newVal);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      handleToggleOptions();
    }
    if (options.find((o) => isGroup(o))) {
      return;
    }
    if (event.code === 'ArrowDown') {
      if (highlightedOption === -1) {
        inputRef.current?.blur();
      }
      setHighlightedOption((prev) => {
        return prev + 1 > options.length - 1 ? options.length - 1 : prev + 1;
      });
    }
    if (event.code === 'ArrowUp') {
      if (highlightedOption === 0) {
        inputRef.current?.focus();
      }
      setHighlightedOption((prev) => (prev - 1 < -1 ? -1 : prev - 1));
    }
    if (event.code === 'Enter' || event.code === 'Space' || event.code === 'ArrowLeft' || event.code === 'ArrowRight') {
      if (highlightedOption > -1 && options[highlightedOption])
        handleSelection((options[highlightedOption] as SelectOption).value as T);
    }
  };

  useEffect(() => {
    if (showOptions) {
      setHighlightedOption(-1);
      document.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [showOptions]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    setSearchString('');
  }, [showOptions]);

  const debouncedHideHandler = useMemo(() => debounce((val: boolean) => setShowOptions(val), 100), [setShowOptions]);

  const handleToggleOptions = () => {
    if (disabled) {
      return;
    }
    debouncedHideHandler.cancel();
    setShowOptions(!showOptions);
  };

  const toggleGroup = (index: number) => {
    if (!hiddenGroup.includes(index)) {
      setHiddenGroup((prev) => [...prev, index]);
    } else {
      setHiddenGroup((prev) => prev.filter((i) => i !== index));
    }
  };

  const showAbove = useMemo(() => {
    if (showOptions) {
      const rect = selectRef.current?.getBoundingClientRect();
      const spaceBelow = (window.innerHeight || 0) - (rect?.bottom || 0);

      if (spaceBelow < DROPDOWN_HEIGHT) {
        return true;
      } else {
        return false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions, clientHeight]);

  const renderOption = (opt: SelectOption<T>, index: number | string) => (
    <li>
      <button
        disabled={disabled || opt.disabled}
        className={`${opt.value === localValue && 'selected'} ${index === highlightedOption && 'highlighted'}`}
        onClick={() => handleSelection(opt.value)}
      >
        {opt.label}
      </button>
    </li>
  );

  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) =>
        option.label?.toLowerCase().includes(searchString?.toLowerCase()) ||
        (isOption(option) &&
          option?.value &&
          option.value.toString().toLowerCase().includes(searchString?.toLowerCase())),
    );
  }, [options, searchString]);

  const pos = selectRef.current?.getBoundingClientRect();
  const listPos = listRef.current?.getBoundingClientRect();
  const optionsTop = !showAbove
    ? (pos?.bottom || 0) - 2
    : (pos?.bottom || 0) -
      (pos?.height || 0) +
      2 -
      ((listPos?.height || 0) < DROPDOWN_HEIGHT ? listPos?.height || 0 : DROPDOWN_HEIGHT);

  return (
    <div className={classNames({ [styles.isOverlayedLabel]: overlayedLabel })}>
      {label && (
        <SpanLabel
          className={classNames({ [styles.isOverlayedLabelSelect]: overlayedLabel })}
          isOverlayed={overlayedLabel}
        >
          {label}
          {required && <Required />}
        </SpanLabel>
      )}

      {note && <Note>{note}</Note>}
      <SelectWrapper ref={selectRef} small={small} fullWidth={fullWidth}>
        {search && showOptions ? (
          <SearchInput
            autoFocus
            type="text"
            ref={inputRef}
            small={small}
            large={large}
            showAbove={showAbove}
            className={`${showOptions && 'active'}`}
            placeholder={searchPlaceholder}
            value={searchString}
            onChange={({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => setSearchString(value)}
          />
        ) : (
          <LabelButton
            small={small}
            large={large}
            showAbove={showAbove}
            disabled={disabled}
            isPlaceholder={!selectedOption?.label || selectedOption?.value === undefined}
            className={`${showOptions && 'active'} ${disabled && ' disabled'} select-button`}
            onClick={handleToggleOptions}
          >
            {selectedOption?.label || placeholder}
            <Icon>
              <KeyboardArrowDown />
            </Icon>
          </LabelButton>
        )}

        <Portal>
          <ListWrapper
            ref={listRef}
            showAbove={showAbove}
            style={{
              opacity: showOptions ? 1 : 0,
              visibility: showOptions ? 'visible' : 'hidden',
              position: 'fixed',
              top: optionsTop < 0 ? 0 : optionsTop,
              maxHeight: optionsTop < 0 ? DROPDOWN_HEIGHT + optionsTop : DROPDOWN_HEIGHT,
              left: pos?.left,
              width: pos?.width,
            }}
          >
            {filteredOptions.length ? (
              filteredOptions.map((opt, index) =>
                isGroup(opt) ? (
                  <li key={opt.label}>
                    <GroupLabel onClick={() => toggleGroup(index)}>
                      <InlineWrapper spaceBetween tag="span">
                        {opt.label}

                        {!hiddenGroup.includes(index) ? <ArrowDropDown /> : <ArrowDropUp />}
                      </InlineWrapper>
                    </GroupLabel>{' '}
                    {!hiddenGroup.includes(index) && (
                      <ul>
                        {opt.options.map((childOpt, childIndex) => (
                          <Fragment key={`${index}-${childIndex}`}>
                            {renderOption(childOpt as SelectOption<T>, `${index}-${childIndex}`)}
                          </Fragment>
                        ))}
                      </ul>
                    )}
                  </li>
                ) : (
                  <Fragment key={`${index}`}>{renderOption(opt as SelectOption<T>, index)}</Fragment>
                ),
              )
            ) : (
              <EmptyState>Could not find value you searched</EmptyState>
            )}
          </ListWrapper>
          {showOptions && (
            <Overlay
              showMobile
              style={{ background: 'none', zIndex: 99999998 }}
              onClick={() => setShowOptions(false)}
            />
          )}
        </Portal>
      </SelectWrapper>
    </div>
  );
};
